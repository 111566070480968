import React from 'react';
import PropTypes from 'prop-types';

class SimpleSelect extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChange(event);
    }

    render() {
        const { id, options, value } = this.props;
        return (
            <div className="input-control">
                <select value={value} onChange={this.handleChange}>
                    {options.map(optionItem => (
                        <option
                            key={`ss:${id}:${optionItem.id}`}
                            value={optionItem.id}
                        >{optionItem.label}</option>
                    ))}
                </select>
            </div>
        );
    }
}

SimpleSelect.defaultProps = {
    id: 'simpleSelect',
    onChange: () => {
    },
    options: [],
    value: '',
};

SimpleSelect.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    value: PropTypes.string.isRequired,
};

export default SimpleSelect;
