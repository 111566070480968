import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SimpleSelect from '../SimpleSelect';

import messagesDe from './translations/de.json';
import messagesEn from './translations/en.json';

const messages = {
    de: messagesDe,
    en: messagesEn,
};

class SelectItemType extends React.Component {
    render() {
        const { id, options, value, language, ...otherProps } = this.props;

        const selectorOptions = options.map(option => ({
            id: option,
            label: option,
        }));

        selectorOptions.push({
            id: '',
            label: messages[language]['SelectItemType.option.static'],
        });

        return (
            <SimpleSelect {...otherProps} {...{ id, options: selectorOptions, value }} />
        );
    }
}

SelectItemType.defaultProps = {
    language: 'de',
    id: 'selectItemType',
    options: [],
    value: '',
};

SelectItemType.propTypes = {
    language: PropTypes.string,
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.string,
    ).isRequired,
    value: PropTypes.string.isRequired,
};

export default injectIntl(SelectItemType);
